$(function(){

    // ニュースのもっと見る機能
    const newsIndexViewMore = function() {
        const button = document.querySelectorAll('.cmp-news-index__button');

        for (let i = 0, length = button.length; i < length; i++) {
            button[i].addEventListener('click', function() {
                const container = this.closest('.news-index');
                const newsIndex = container.querySelector('.cmp-news-index');
                const hiddenItem = newsIndex.querySelectorAll('.cmp-news-index__hidden');
                const addNumber = newsIndex.getAttribute('data-news-index-additems');

                // 要素を設定されている数だけ表示
                for (let j = 0, length = addNumber; j < length; j++) {
                    if (typeof hiddenItem[j] !== 'undefined') {
                        hiddenItem[j].classList.remove('cmp-news-index__hidden');
                    }
                }

                // 表示させる要素がなくなったらボタンを非表示
                if (newsIndex.querySelectorAll('.cmp-news-index__hidden').length == 0) {
                    this.style.display = 'none';
                }
            }, false);
        }

    }();

});
