export function correspondenceOfNavAndPath() {
  return {
    "/ja/company.html": { "navCategory": "company" },
    "/ja/company/": { "navCategory": "company" },
    "/ja/actions/vision2030.html": { "navCategory": "company" },
    "/ja/business.html": { "navCategory": "company" },
    "/ja/business/": { "navCategory": "company" },

    "/ja/entertainment.html": { "navCategory": "company" },
    "/ja/entertainment/": { "navCategory": "company" },

    "/ja/ir.html": { "navCategory": "ir" },
    "/ja/ir/": { "navCategory": "ir" },
    "https://ceh.cosmo-oil.co.jp/ir/meeting/": { "navCategory": "ir" },
    "/ja/ir/event/": { "navCategory": "ir" },

    "/ja/sustainability.html": { "navCategory": "sustainability" },
    "/ja/sustainability/": { "navCategory": "sustainability" },

    "/ja/actions.html": { "navCategory": "initiative" },
    "/ja/actions/": { "navCategory": "initiative" },

    "/ja/services.html": { "navCategory": "service" },
    "/ja/services/": { "navCategory": "service" },
    "https://www.cosmooil.net": { "navCategory": "service" },
    "https://www.cosmooil.net/": { "navCategory": "service" },

    "/ja/press.html": { "navCategory": "press" },
    "/ja/press/": { "navCategory": "press" },
    "/ja/information/press/": { "navCategory": "press" },

    "/ja/topics.html": { "navCategory": "topic" },
    "/ja/topics/": { "navCategory": "topic" },
    "/ja/information/topics/": { "navCategory": "topic" },

    "/ja/contact.html": { "navCategory": "contact" },
    "/ja/contact/": { "navCategory": "contact" },

    "/ja/faq.html": { "navCategory": "faq" },
    "/ja/services/faq/": { "navCategory": "faq" },

    "/en/company.html": { "navCategory": "company" },
    "/en/company/": { "navCategory": "company" },
    "/en/actions/vision2030.html": { "navCategory": "company" },
    "/en/business.html": { "navCategory": "company" },
    "/en/business/": { "navCategory": "company" },

    "/en/ir.html": { "navCategory": "ir" },
    "/en/ir/": { "navCategory": "ir" },

    "/en/sustainability.html": { "navCategory": "sustainability" },
    "/en/sustainability/": { "navCategory": "sustainability" },

    "/en/actions.html": { "navCategory": "initiative" },
    "/en/actions/": { "navCategory": "initiative" },

    "/en/press.html": { "navCategory": "press" },
    "/en/information/press/": { "navCategory": "press" }
  }
};
