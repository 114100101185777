// イージング用の関数（easeInOutCubic）
const easingFunction = (t) => (
    t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
);

// スクロール動作単体定義
const animateScroll = (target, duration = 500, verticalOffset = 0) => {
    const initialPosition = window.scrollY;

    const targetPosition = (() => {
        let ret = target.getBoundingClientRect().top + initialPosition;

        // ヘッダ避け
        if((initialPosition - ret) < 0) {
            return ret;
        }
        return ret - verticalOffset;
    })();

    const animationStart = performance.now();

    const performAnimation = (currentTime) => {
        const elapsedTime = currentTime - animationStart;
        const progress = elapsedTime / duration;

        if (progress < 1) {
            const easedProgress = easingFunction(progress);
            const currentPosition = initialPosition +
                ((targetPosition - initialPosition) * easedProgress);
            window.scrollTo(0, currentPosition);
            requestAnimationFrame(performAnimation);
        } else {
            window.scrollTo(0, targetPosition);
        }
    };

    requestAnimationFrame(performAnimation);
};

// animateScroll() を クリックに紐付けする処理
const bindSmoothScroll = (triggers) => {
    triggers.forEach((triggers) => {
        triggers.addEventListener('click', (event) => {
            event.preventDefault();
            const targetId = triggers.getAttribute('href');
            const targetSection = document.getElementById(targetId.replace('#', ''));

            const gheader = document.querySelector('.gheader');
            const heightHeader = gheader ? gheader.clientHeight : 0;

            animateScroll(targetSection, 500, heightHeader);
        });
    });
}

export default bindSmoothScroll;
