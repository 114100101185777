$(function(){

  var flag = '';
  var w = window.innerWidth;
  var current_w = window.innerWidth;
  var w_resize = window.innerWidth;

  $(window).resize(function() {
    current_w = window.innerWidth;
    if (current_w >= 1255 && flag != 'pc') {
      w_resize = window.innerWidth;
      if (w != w_resize){
        flag = 'pc'
        $('.js-headerAccordionItem,.js-footerAccordionItem').css({'display': 'block'});
        $('.js-headerPullDownItem').css({'display': 'flex'});
        $('.js-headerPullDownItem').css({'display': 'none'});
        $('.js-headerAccordionTrigger, .js-footerAccordionTrigger').removeClass('is-active');
        w = window.innerWidth;
      }
    }else if(current_w < 1255 && flag != 'sp'){
      w_resize = window.innerWidth;
      if (w != w_resize){
        flag = 'sp';
        $('.js-headerAccordionItem, .js-footerAccordionItem').css({'display': 'block'});
        $('.js-headerAccordionItem, .js-footerAccordionItem').css({'display': 'none'});
        $('.js-headerHamburgerItem').css({'display': 'none'});
        $('.js-headerHamburgerLine').removeClass('is-active');
        w = window.innerWidth;
      }
    }
  });
  $('.js-headerPullDownTrigger').hover(
    function(){
      if (window.matchMedia("(min-width: 1255px)").matches) {
        $(this).find('.js-headerPullDownItem').css({'display': 'flex'});
        $(this).find('.js-headerPullDownItem').addClass('is-active');
        $(this).find('.js-headerHoverUnderline').addClass('is-active');
      }
    },
    function(){
      if (window.matchMedia("(min-width: 1255px)").matches) {
        $(this).find('.js-headerPullDownItem').css({'display': 'none'});
        $(this).find('.js-headerHoverUnderline').removeClass('is-active');
      }
    }
  );
  $('.js-headerAccordionTrigger, .js-footerAccordionTrigger').click(function(e){
    if (window.matchMedia("(max-width: 1254px)").matches) {
      e.preventDefault();
      $(this).siblings('.js-headerAccordionItem, .js-footerAccordionItem').slideToggle(500);
      $(this).toggleClass('is-active');
    }
  });
  $('.js-headerHamburgerTrigger').click(function(){
    if (window.matchMedia("(max-width: 1254px)").matches) {
      $('.js-headerHamburgerLine').toggleClass('is-active');
      $('.js-headerHamburgerItem').slideToggle(500);
    }
  })


  $('.js-footerScrolltopTrigger').hide();
  $('.js-footerScrolltopTrigger').click(function(e){
    e.preventDefault();
    $("html, body").animate({scrollTop: 0}, 500);
  });
  let footerScrollHeight, footerScrollPosition, footerHeight, windowScrollPosition;
  windowScrollPosition = $(window).scrollTop();
  if ( windowScrollPosition > 0 ) {
    $('.js-footerScrolltopTrigger').fadeIn();
  } else {
    $('.js-footerScrolltopTrigger').fadeOut();
  }
  $(window).scroll(function(){
    windowScrollPosition = $(window).scrollTop();
    footerScrollHeight = $(document).height();
    footerScrollPosition = $(window).height() + windowScrollPosition;
    footerHeight = $('.js-footerScrolltopMarker').innerHeight();
    if ( windowScrollPosition > 0 ) {
      $('.js-footerScrolltopTrigger').fadeIn();
    } else {
      $('.js-footerScrolltopTrigger').fadeOut();
    }
    if (window.matchMedia("(max-width: 1254px)").matches) {
      if ( footerScrollHeight - footerScrollPosition +40  <= footerHeight ) {
        $('.js-footerScrolltopTrigger').addClass('is-absolute');
      } else {
        $('.js-footerScrolltopTrigger').removeClass('is-absolute');
      }
    }else{
      if (footerScrollHeight - footerScrollPosition +85  <= footerHeight ) {
        $('.js-footerScrolltopTrigger').addClass('is-absolute');
      } else {
        $('.js-footerScrolltopTrigger').removeClass('is-absolute');
      }
    }
  });


  const switchLanguage = function() {
    const target = document.querySelector('.js-languageSwitch');

    target.setAttribute('href', `/bin/switchlanguage?path=` + getPath());

    function getPath() {
      const path = window.location.pathname;

      if (path.search(/\/ja\//) !== -1) {
        return path.replace('/ja/', '/en/');
      } else if (path.search(/\/en\//) !== -1) {
        return path.replace('/en/', '/ja/');
      }
    }
  }();


});
