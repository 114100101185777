const initPositionPagetopLink = () => {
  const breakpoint = 899;
  const elemFooter = document.querySelector('footer.experiencefragment');;
  const elemPagetopLink = document.querySelector('.link_to_pagetop');
  const classnameSticky = 'stickToTopOfFooter';
  const selectorContentPath = '.breadcrumb';
  const elemContentPath = (() => {
    const testExistancContentPathBottom = Array.from(document.querySelectorAll(selectorContentPath))[1]

    return(
      testExistancContentPathBottom
      ? testExistancContentPathBottom
      : null
    );
  })();

  // Sub Routines
  const _getElemWatch = () => {
    if((window.innerWidth <= breakpoint) && elemContentPath) {
      return elemContentPath;
    }

    return elemFooter;
  };

  const _getStyleForContentPath = () => {
    return `calc(-48px - 16px - ${elemContentPath.clientHeight.toString()}px)`;
  }

  const _getScrollDirectionVertical = (intersectionEntry) => {
    const scrolledCurrent = intersectionEntry.boundingClientRect.y
    const ratioCurrent = intersectionEntry.intersectionRatio;

    const scrolledPrev = intersectionEntry.target['scrolledPrev'];
    const ratioPrev = intersectionEntry.target['ratioPrev'];

    let ret = 'non-non';

    if(scrolledCurrent < scrolledPrev) {
      if(ratioCurrent > ratioPrev && intersectionEntry.isIntersecting) {
        ret = 'down-enter';
      }
      else {
        ret = 'down-leave';
      }
    }
    else if(scrolledCurrent > scrolledPrev && intersectionEntry.isIntersecting) {
      if(ratioCurrent < ratioPrev) {
        ret = 'up-leave';
      }
      else {
        ret = 'up-enter';
      }
    }

    intersectionEntry.target['scrolledPrev'] = scrolledCurrent;
    intersectionEntry.target['ratioPrev'] = ratioCurrent;

    return ret;
  };

  const observer = new IntersectionObserver((entries) => {
    for(const entry of entries) {
      const scrollDirectionVertical = _getScrollDirectionVertical(entry);

      if(scrollDirectionVertical === 'down-leave') { return; }

      if(entry.isIntersecting) {
        elemPagetopLink.classList.add(classnameSticky);

        if(elemContentPath) {
          elemPagetopLink.style.top = _getStyleForContentPath();
        }
      }
      else {
        elemPagetopLink.style.removeProperty('top');
        elemPagetopLink.classList.remove(classnameSticky);
      }
    }
  });

  // Main Routine
  [elemContentPath, elemFooter].forEach((target) => {
    if(!target) { return; }

    target['scrolledPrev'] = 0;
    target['ratioPrev'] = 0;
  });

  observer.observe(_getElemWatch());

  window.addEventListener('resize', () => {
    observer.unobserve(elemFooter);
    if(elemContentPath) { observer.unobserve(elemContentPath); }

    observer.observe(_getElemWatch());
  });
};

const fadePagetopLinkOnScroll = () => {
  const elemPagetopLink = document.querySelector('.link_to_pagetop');
  const classnameFadedOut = 'fadedOut';

  const _movement = () => {
    const scrollDistance = window.scrollY;

    if(scrollDistance > window.innerHeight) {
      elemPagetopLink.classList.remove(classnameFadedOut);
    }
    else {
      elemPagetopLink.classList.add(classnameFadedOut);
    }
  };

  _movement();

  window.addEventListener('scroll', () => {
    _movement();
  });
};

// イージング用の関数（easeOutExpo）
const easingFunction = (t) => (
  t === 1 ? 1 : 1 - Math.pow(2, -10 * t)
);

// スクロール動作単体定義
const animateScroll = (target, duration = 900) => {
  const initialPosition = window.pageYOffset;
  const targetPosition = target.getBoundingClientRect().top + initialPosition;
  const animationStart = performance.now();

  const performAnimation = (currentTime) => {
    const elapsedTime = currentTime - animationStart;
    const progress = elapsedTime / duration;

    if (progress < 1) {
      const easedProgress = easingFunction(progress);
      const currentPosition = initialPosition +
        ((targetPosition - initialPosition) * easedProgress);
      window.scrollTo(0, currentPosition);
      requestAnimationFrame(performAnimation);
    } else {
      window.scrollTo(0, targetPosition);
    }
  };

  requestAnimationFrame(performAnimation);
};

// animateScroll() を クリックに紐付けする処理
const bindSmoothScroll = (triggers) => {
  triggers.forEach((triggers) => {
    triggers.addEventListener('click', (event) => {
      event.preventDefault();
      const targetId = triggers.getAttribute('href');
      const targetSection = document.body;
      animateScroll(targetSection);
    });
  });
}

$(() => {
  initPositionPagetopLink(document.querySelector('.link_to_pagetop'));
  bindSmoothScroll(document.querySelectorAll('.link_to_pagetop__content'));
  fadePagetopLinkOnScroll();
});
